import { ReportTypeDetailResponse } from '@/models/poultry/reportType.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Module, Mutation, VuexModule, getModule, Action } from "vuex-module-decorators";
import { Endpoints } from '../endpoints';
import store from '../index';

@Module({
    namespaced: true,
    dynamic: true,
    name: 'reportType.details',
    store
})
class ReportTypeDetailStore extends VuexModule {
    isLoading = false;
    isDeleted = false;
    error: ErrorResponse | null = null;
    reportType = {} as ReportTypeDetailResponse;
    reportTypeId: number = 0;

    @Mutation
    updateReportType(params: ReportTypeDetailResponse) {
        this.reportType = params;
    }

    @Mutation
    setError(params: ErrorResponse) {
        this.error = params;
    }

    @Mutation
    updateLoadingStatus(params: boolean) {
        this.isLoading = params;
    }

    @Mutation
    resetReportTypeId() {
        this.reportType = {} as ReportTypeDetailResponse;
    }

    @Mutation
    updateReportTypeId(reportTypeId: any) {
        const id: number = reportTypeId;
        this.reportTypeId = id;
    }

    @Action
    async fetchById() {
        this.context.commit('updateLoadingStatus', true);
        try {
            await axios.get(`${Endpoints.ReportTypes}/${this.reportTypeId}`).then((response) => {
                this.context.commit('updateReportType', response.data);
            });
        } catch (error) {
            this.context.commit('setError', error.data.reportType);
        }
        this.context.commit('updateLoadingStatus', false);
    }

}

export default getModule(ReportTypeDetailStore)