import dayjs from 'dayjs';

export interface DropdownOption {
  text: string;
  value: string;
}

let startYear = 2019;

const generatedMonths = [];
const generatedYears: DropdownOption[] = [
  {
    text: '2019',
    value: '2019',
  },
];

const month = dayjs().startOf('year');

for (let i = 0; i < 12; i++) {
  const m = month.add(i, 'month');
  generatedMonths.push({
    text: m.format('MMMM'),
    value: m.format('MM'),
  });
}

let currentYear = new Date().getFullYear();

while (startYear++ < currentYear) {
  if (
    generatedYears.findIndex((el) => {
      return el.value === String(currentYear);
    })
  ) {

    generatedYears.push({
      text: startYear.toString(),
      value: startYear.toString(),
    });
  }
}

export const MONTHS = generatedMonths;
export const YEARS = generatedYears.reverse();

export function getCurrentYear(): string {
  return dayjs().format('YYYY');
}

export function getCurrentMonth(): DropdownOption {
  const now = dayjs();

  return {
    text: now.format('MMMM'),
    value: now.format('MM'),
  };
}
