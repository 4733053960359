



























































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class Card extends Vue {
  @Prop()
  private count!: string | number;

  @Prop()
  private green: boolean | undefined;

  @Prop({
    type: String,
  })
  private subtitle!: string;

  @Prop({
    type: Object,
  })
  private gvpHighest!: object;

  @Prop({
    type: Object,
  })
  private gvpLowest!: object;

  @Prop({
    type: Object,
  })
  private bioHighest!: object;

  @Prop({
    type: Object,
  })
  private bioLowest!: object;

  @Prop({
    type: Number,
  })
  private drinkingWaterVaccinationReportCount!: number;

  @Prop({
    type: Number,
  })
  private injectableVaccinationReportCount!: number;

  @Prop({
    type: Number,
  })
  private hatcherySprayVaccinationReportCount!: number;

  @Prop({
    type: Number,
  })
  private cleaningAndDisinfectionReportCount!: number;

  @Prop({
    type: Number,
  })
  private integratedPestManagementReportCount!: number;

  @Prop({
    type: Number,
  })
  private labOnWheelReportCount!: number;

  @Prop({
    type: Number,
  })
  private pippedEmbryoAnalysisReportCount!: number;

  @Prop({
    type: Number,
  })
  private eggBreakOutAnalysisReportCount!: number;

  get hasGVPAuditChildren(): boolean {
    return Boolean(this.gvpHighest) || Boolean(this.gvpLowest);
  }

  get hasBiosecurityAuditChildren(): boolean {
    return Boolean(this.bioHighest) || Boolean(this.bioLowest);
  }

  get hasReports(): boolean {
    return Boolean(this.drinkingWaterVaccinationReportCount) ||
      Boolean(this.injectableVaccinationReportCount) ||
      Boolean(this.hatcherySprayVaccinationReportCount) ||
      Boolean(this.cleaningAndDisinfectionReportCount) ||
      Boolean(this.integratedPestManagementReportCount) ||
      Boolean(this.labOnWheelReportCount) ||
      Boolean(this.pippedEmbryoAnalysisReportCount) ||
      Boolean(this.eggBreakOutAnalysisReportCount)
  }
}
