export const DEFAULT_COUNT_PER_PAGE = 10;

export enum ReportTypeNames {
  water = 'Water',
  injectable = 'Injectable',
  hatchery = 'Hatchery',
  cnd = 'Cleaning and Disinfection',
  ipm = 'Integrated Pest Management',
  labOnWheel = 'Lab on Wheel',
  pea = 'Pipped Embryo Analysis',
  eba = 'Egg Break Out Analysis',
}

export enum ReportNames {
  water = 'Drinking Water Vaccination',
  injectable = 'Injectable Vaccination',
  hatchery = 'Hatchery Spray Vaccination',
  cnd = 'Cleaning and Disinfection',
  ipm = 'Integrated Pest Management',
  labOnWheel = 'Lab on Wheel',
  pea = 'Pipped Embryo Analysis',
  eba = 'Egg Break Out Analysis',
}

export enum speciesType {
  Swine = 'Swine',
  Poultry = 'Poultry',
}

export enum speciesId {
  Swine = 1,
  Poultry = 2,
}

export enum ReportTypeIds {
  water = '2',
  injectable = '3',
  hatchery = '4',
  cnd = '5',
  ipm = '6',
  labOnWheel = '7',
  pea = '8',
  eba = '9',
}

export enum ReportComparisonType {
  betweenFarm = 'between_farms',
  betweenClient = 'between_clients',
  betweenComplexes = 'between_complexes',
  betweenHatchers = 'between_hatchers',
  betweenTrays = 'between_trays',
  betweenPeaHatchers = 'between_pea_hatchers',
}

export enum DownloadDocumentType {
  excel,
  pdf,
}
