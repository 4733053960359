






































































import { Vue, Component } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import Card from '@/components/poultry/Card.vue';
import Dropdown from '@/components/poultry/Dropdown.vue';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { DashboardResponse } from '@/models/poultry/dashboard.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/store/poultry/endpoints';
import { getCurrentYear, MONTHS, YEARS, getCurrentMonth } from '@/utils/dayjs';
import DashboardClientTable from './DashboardClientTable.vue';

@Component({
  components: {
    Page,
    Card,
    Dropdown,
    DashboardClientTable,
  },
})
export default class Dashboard extends Vue {
  dashboard = {
    totalClients: 0,
    totalPublishedReports: 0,

  } as DashboardResponse;
  year = getCurrentYear();
  currentMonth = getCurrentMonth();
  isLoading = false;

  get hasNewClients(): boolean {
    return this.dashboard.totalClients > 0;
  }

  get monthsOption(): DropdownOption[] {
    return MONTHS;
  }

  get yearsOption(): DropdownOption[] {
    return YEARS;
  }

  get auditScore(): string {
    return `${this.dashboard.averageAuditScore}%`;
  }

  created() {
    document.title = 'Elanco | Dashboard'
    this.fetchData();
  }

  handleMonthChanged(month: DropdownOption) {
    this.currentMonth = { ...month };
    this.fetchData();
  }

  handleYearChanged(params: DropdownOption) {
    this.year = params.value;
    this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;
    try {
      const { data } = await axios.get(`${Endpoints.Dashboard}/poultry`, {
        params: {
          year: this.year,
          month: this.currentMonth.value,
        },
      });
      this.dashboard = data;
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  handleDropdownChanged() {}
}
