

























import { Prop, Component, Vue } from 'vue-property-decorator';
import { TopTenCompany } from '@/models/poultry/dashboard.model';

@Component
export default class DashboardClientTable extends Vue {
  @Prop({
    default: () => [],
    required: true,
  })
  companies!: TopTenCompany[];
}
