import { ErrorResponse } from '@/models/poultry/response';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '../index';
import { ReportTypeResponse } from '@/models/poultry/reportType.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '../endpoints';
import { speciesId } from '@/constants';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'reportType.list',
  store,
})
class ListReportTypeStore extends VuexModule {
  isLoading = false;
  error: ErrorResponse | null = null;
  reportTypes: ReportTypeResponse[] = [];
  currentSort: keyof ReportTypeResponse = 'name';
  currentSortOrder = { reverse: true };

  get hasReportTypes(): boolean {
    return this.isLoading === false && Boolean(this.reportTypes.length);
  }

  @Mutation
  updateReportTypes(params: ReportTypeResponse[]) {
    this.reportTypes = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Action
  async fetchAll(): Promise<void> {
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios
        .get(`${Endpoints.ReportTypes}`, {
          params: {
            speciesId: speciesId.Poultry,
          },
        })
        .then((response) => {
          this.context.commit('updateReportTypes', response.data);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }

    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ListReportTypeStore);
