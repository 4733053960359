export enum Endpoints {
  Signin = '/accounts/login',
  Signout = '/account/signout',
  Profile = '/profile',
  RecoverPassword = '/account/recovery',
  ResetPassword = '/account/reset',
  AuditorsAutocomplete = '/auditors/autocomplete',
  Clients = '/clients',
  Auditors = '/auditors',
  Countries = '/countries',
  States = '/states',
  Regions = '/regions',
  Cities = '/cities',
  Reports = '/audit_reports',
  ReportTypes = '/audit_types',
  ReportsTotalCount = '/audit_reports/total_count',
  Dashboard = '/dashboard',
  ResultsAnalysis = '/result_analysis',
  PreparationAnalysis = '/preparation_analysis',
  VaccinationAnalysis = '/vaccination_analysis',
  HatcheryAnalysis = '/hatchery_analysis',
  VisualAnalysis = '/visual_analysis',
  SwabSampleAnalysis = '/swab_sample_analysis',
  Users = '/users',
}
